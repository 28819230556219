<template>
  <b-sidebar
    id="merchant-transfer-account-config-sidebar"
    :visible="isMerchantTransferAccountConfigSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-merchant-transfer-account-config-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Virtual Account Range Configuration
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <!-- Merchant NO. -->
          <b-form-group
            label="MERCHANT NO"
            label-for="merchant-no"
          >
            <b-form-input
              id="merchant-no"
              v-model="merchantNo"
              autofocus
              trim
              disabled
            />
          </b-form-group>

          <!-- Type -->
          <b-form-group
            label="PROVIDER"
            label-for="provider-type"
          >
            <v-select
              v-model="providerType"
              :options="typeOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="provider-type"
            />
          </b-form-group>

          <div v-if="providerType === '1'">

            <!-- Chargeback Coverage -->
            <validation-provider
              #default="{ errors }"
              name="Allocation Ranges"
              rules="required"
            >
              <b-form-group
                label="Allocation Ranges"
                label-for="allocation-ranges"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-input
                      id="allocation-ranges"
                      v-model="allocationRanges"
                      type="number"
                      min="0"
                      autofocus
                      trim
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="rangeOption"
                      :options="rangeOptions"
                      :clearable="false"
                      :searchable="false"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </validation-provider>
          </div>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isFormValid"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useMerchantsJs from './merchants'

const {
  submitTransferAccountRangeConfigs,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {

    Ripple,
  },
  model: {
    prop: 'isMerchantTransferAccountConfigSidebarActive',
    event: 'update:is-merchant-transfer-account-config-sidebar-active',
  },
  props: {
    isMerchantTransferAccountConfigSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      merchantNo: '',
      providerType: '1',
      allocationRanges: 1,
      rangeOption: 1000,
      error: '',
      typeOptions: [
        { label: 'STP', value: '1' },
      ],
      rangeOptions: [
        1000,
      ],
      required,
    }
  },
  computed: {
    isFormValid() {
      return this.allocationRanges < 1
    },
  },
  watch: {
    isMerchantTransferAccountConfigSidebarActive(val) {
      if (!val) {
        return
      }
      this.initMerchantInfo(this.data)
    },
  },
  methods: {
    initMerchantInfo(info) {
      this.merchantNo = info.merchantNo
    },
    initValues() {
      this.merchantNo = ''
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.updateItem()
            }
          })
        }
      })
    },
    updateItem() {
      const ranges = this.allocationRanges * this.rangeOption

      const obj = {
        merchant_no: this.merchantNo,
        provider: 'STP',
        ranges,
      }

      submitTransferAccountRangeConfigs(
        obj,
        () => {
          this.showAlert('success', 'Updated!', 'Success')
          this.$emit('update:is-merchant-transfer-account-config-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
      this.initValues()
    },
  },
}
</script>
