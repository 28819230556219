<template>
  <div>
    <transfer-account-range-detail
      :is-merchant-transfer-account-config-sidebar-active.sync="isMerchantTransferAccountConfigSidebarActive"
      :data="merchantConfigInfo"
      @updated="fetchByMerchant"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-list' }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="addNewAccount"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Add New</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="accounts"
        primary-key="account_id"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(prefix)="data">
          <b-media vertical-align="center">
            {{ data.item.provider_format }}{{ data.item.region_format }}{{ data.item.client_format }}
          </b-media>
        </template>
        <template #cell(provider)="data">
          <b-media vertical-align="center">
            {{ data.item.provider_type }}
          </b-media>
        </template>
        <template #cell(range)="data">
          <b-media vertical-align="center">
            {{ data.item.sub_client_format }}
          </b-media>
        </template>

        <template #cell(reference)="data">
          <b-media vertical-align="center">
            {{ data.item.reference_format }}
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton, BMedia, BPagination,
} from 'bootstrap-vue'
import useMerchantsJs from './merchants'
import TransferAccountRangeDetail from './TransferAccountRangeDetail.vue'

const {
  fetchTransferAccountRangeConfigs,
} = useMerchantsJs()

export default {
  components: {
    TransferAccountRangeDetail,

    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BMedia,
    BPagination,
  },
  data() {
    return {
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      filter: null,
      totalCount: 20,
      isMerchantTransferAccountConfigSidebarActive: false,
      merchantConfigInfo: {},
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      tableFields: [
        'prefix',
        'provider',
        'range',
        'reference',
      ],
      accounts: [{
        provider_format: '', region_format: '', client_format: '', provider_type: '', sub_client_format: '', reference_format: '',
      }],
      accountInfo: {},
      cpageNum: 1,
      isSidebarActive: false,
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.cpageNum = pageNum
      this.fetchByMerchant()
    },
  },
  created() {
    this.fetchByMerchant()
  },
  methods: {
    fetchByMerchant() {
      const param = {
        merchant_no: this.merchantNo,
        page_num: this.cpageNum,
        page_size: 10,
      }
      fetchTransferAccountRangeConfigs(this, param, data => {
        this.accounts = data.list
        this.totalCount = data.total
        this.recalMetaData()
      })
    },
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalCount - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalCount
      this.dataMeta.of = this.totalCount
    },
    addNewAccount() {
      this.merchantConfigInfo = {
        merchantNo: this.merchantNo,
      }
      this.isMerchantTransferAccountConfigSidebarActive = true
    },
  },
}
</script>
